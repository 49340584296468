<template>
  <v-container
    :id="`${displayAsPlural.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ displayAsSingular }} Maintenance</h3>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <v-row
                no-gutters
                align="end"
              >
                <v-col
                  cols="12"
                  sm="12"
                  lg="5"
                >
                  <select-customer
                    v-if="customers.data.length > 1"
                    id="2-maintain-customer"
                    v-model="customers.selectedItem"
                    :appendhint="`to view its ${displayAsPlural}`"
                  />
                </v-col>
              </v-row>

              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <prompt-group-table
                    ref="maintenancePromptGroupTable"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import MaterialCard from '@/components/MaterialCard'
  import PromptGroupTable from '@/components/maintenance/tables/PromptGroupTable'

  export default {
    name: 'PromptGroupsView',

    components: {
      MaterialCard,
      PromptGroupTable,
    },

    data () {
      return {
        appItem: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsSingular: get('promptgroup/displayAsSingular'),
      displayAsPlural: get('promptgroup/displayAsPlural'),
    },

    created () {
      this.appItem = this.getAppItem(this.$options.name)
      this.$store.dispatch('user/setPageName', this.$options.name)
    },
  }
</script>
